<template>
  <div class="tap-point-wrapper">
    <div class="summary">
      <h4>포인트 정보</h4>
      <card-basic>
        <div class="current-point">
          <p class="sub-text-s2 text-gray-reverse">
            보유중인 <br />
            빅크 포인트
          </p>
          <h3 class="text-gray-reverse">{{ state.usablePoints }}</h3>
        </div>
        <div class="accumulate-point">
          <span class="sub-text-s2 text-gray-second">누적 적립 포인트</span>
          <span class="sub-text-s1">{{ state.savedPoint }}</span>
        </div>
      </card-basic>
    </div>

    <div class="point-section">
      <h4>포인트 내역</h4>

      <div class="table-header sub-text-s3 text-gray-second">
        <span class="col1">포인트 유형</span>
        <span class="col2">적립 포인트</span>
        <span class="col3">포인트 상태</span>
        <span class="col4">일시</span>
        <span class="col5">사용 유효 기간</span>
      </div>

      <ul v-if="!state.emptyList" class="point-list">
        <li v-for="point in state.points" :key="`point-${point.resourceId}`">
          <point-item :point="point"></point-item>
        </li>
      </ul>

      <empty-list
        v-if="state.emptyList"
        icon-image="/assets/images/settlement/point_empty.png"
        text="포인트 내역이 없습니다."
      ></empty-list>
    </div>
  </div>
</template>

<script>
import CardBasic from "../../../../components/console/cards/CardBasic";
import PointItem from "../PointItem/PointItem";
import { computed, onBeforeMount, reactive } from "vue";
import EmptyList from "../../../../components/console/empties/EmptyList";
import { useStore } from "vuex";
import { pointFormat } from "@/helper/settlement";
export default {
  name: "TabPoint",
  components: { EmptyList, PointItem, CardBasic },
  setup() {
    const store = useStore();

    const state = reactive({
      emptyList: false,
      points: computed(() => {
        return store.getters["settlements/settlementPoints"];
      }),
      pointsMeta: computed(() => {
        return store.getters["settlements/settlementPointsMeta"];
      }),
      savedPoint: computed(() => {
        return pointFormat(state.pointsMeta.savedPoint);
      }),
      usablePoints: computed(() => {
        return pointFormat(state.pointsMeta.usablePoints);
      }),
    });

    onBeforeMount(() => {
      store.dispatch("settlements/getSettlementPoint").then(() => {
        console.log(state.points);
      });
    });

    return { state };
  },
};
</script>

<style src="./style.css" scoped></style>
