<template>
  <div :class="state.statusClassName">
    <card-basic class="point-item-mo">
      <h4>브랜드 홈 개설하기</h4>
      <ul>
        <li>
          <span class="b-text-3 text-gray-second">적립 포인트</span>
          <span class="b-text-1">{{ state.point }}</span>
        </li>
        <li>
          <span class="b-text-3 text-gray-second">포인트 상태</span>
          <!--	적립, 정산, 소멸  -->
          <span class="b-text-1 status">{{ state.statusText }}</span>
        </li>
        <li>
          <span class="b-text-3 text-gray-second">일시</span>
          <span class="b-text-1">{{ state.createdAt }}</span>
        </li>
        <li>
          <span class="b-text-3 text-gray-second">사용 유효 기간</span>
          <span class="b-text-1">{{ state.expiredAt }}</span>
        </li>
      </ul>
    </card-basic>

    <div class="point-item-pc sub-text-s2">
      <span class="col1">{{ point.title }}</span>
      <span class="col2">{{ state.point }}</span>
      <span class="col3 status">{{ state.statusText }}</span>
      <span class="col4">{{ state.createdAt }}</span>
      <span class="col5">{{ state.expiredAt }}</span>
    </div>
  </div>
</template>

<script>
import CardBasic from "../../../../components/console/cards/CardBasic";
import { computed, reactive } from "vue";
import { pointFormat } from "@/helper/settlement";
import settlementConst from "@/consts/settlement";
import { dateFormat } from "@/helper/date";
export default {
  name: "PointItem",
  components: { CardBasic },
  props: {
    point: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      point: computed(() => {
        return pointFormat(props.point.point);
      }),
      statusClassName: computed(() => {
        switch (props.point.type) {
          case settlementConst.pointType.save.value:
            return "accumulate";
          case settlementConst.pointType.use.value:
            return "calculate";
          default:
            return "disappear";
        }
      }),
      statusText: computed(() => {
        switch (props.point.type) {
          case settlementConst.pointType.save.value:
            return settlementConst.pointType.save.text;
          case settlementConst.pointType.use.value:
            return settlementConst.pointType.use.text;
          default:
            return settlementConst.pointType.disappear.text;
        }
      }),
      createdAt: computed(() => {
        return dateFormat(props.point.createdAt, "YYYY.MM.DD");
      }),
      expiredAt: computed(() => {
        return dateFormat(props.point.expiredAt, "YYYY.MM.DD");
      }),
    });

    return { state };
  },
};
</script>

<style src="./style.css" scoped></style>
